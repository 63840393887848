/* Importing fonts from Google */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

/* Reseting */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif!important;
}

.bg_footer { 
  color: #aaa !important;
  background: hsla(208, 33%, 21%, 1);

  background: linear-gradient(
    90deg,
    hsla(208, 33%, 21%, 1) 0%,
    hsla(211, 36%, 46%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(208, 33%, 21%, 1) 0%,
    hsla(211, 36%, 46%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(208, 33%, 21%, 1) 0%,
    hsla(211, 36%, 46%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#243748", endColorstr="#4B749F", GradientType=1 );
}

.bg_header{
  background: -webkit-linear-gradient( 90deg, rgb(0 150 136 / 51%) 0%, #dee2e6 100% );
}
.carousel-inner {
  height: 538px;
}

.carousel-control-prev {
  height: 50px;
  left: unset !important;
  position: fixed !important;
  top: -5px !important;
  right: 50%;
  color: gray !important;
}

.carousel-control-next {
  height: 50px;
  right: unset !important;
  position: fixed !important;
  top: -5px !important;
  left: 50%;
  color: gray !important;
}
.carousel-inner{
  height: auto!important;
}
.testimonialbg{
  background: -webkit-linear-gradient( 90deg, rgb(0 150 136 / 51%) 0%, #dee2e6 100% );
}
.css-cveggr-MuiListItemIcon-root {
  min-width: 35px!important;
}
.css-2s90m6-MuiAvatar-root {
  background-color: #f6f7f7!important;
}
         
.btn-contact {
  background-image: linear-gradient(to right, #314755 0%, #26a0da  51%, #314755  100%);
  margin: 10px;
  padding: 10px 40px!important;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white!important;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-contact:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.bg-clientEx{
  background: #67B26F;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #fefefc, #e4f7f5);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #fefefc, #e4f7f5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}