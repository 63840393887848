.hero-banner h1 {
  font-size: 48px;
}
.hero-banner .btn {
  position: relative;
  overflow: hidden;
  z-index: 9;
}
.hero-banner .btn-primary {
  color: #2f2f41;
  background-color: rgba(1, 164, 121, 0.1);
  border-color: #01a479;
}
.hero-banner .btn:before {
  content: "";
  background: rgb(241, 139, 50);
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.hero-banner .btn:hover:before {
  width: 100%;
}
