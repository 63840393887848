.btn-ques{
    margin: 0rem!important;
    font-size: 1.1rem!important;
    background-color: transparent!important;
    color: indigo;
    padding: 0.4rem 1rem!important;
}
.btn-ques:hover {
    color: #c21212!important;
    background-color: #fafafa00!important;
}
.opening-banner{
    width: 90%!important;
}
.bg-accordian{
    /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
    background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
}
.bg-headingAccordian{
    background-image: linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%);
}